import React, { useRef } from 'react'
import Popup from 'reactjs-popup';
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import BurgerMenu from '../../components/BurgerMenu'
import './Home.scss'
import './Iframe.scss'
import './Modal.scss'
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Pagination, Thumbs } from 'swiper'
import TagManager from 'react-gtm-module'
import ReactPixel from 'react-facebook-pixel'
import { useCookies } from 'react-cookie';

SwiperCore.use([Navigation, Pagination, Thumbs]);

// https://codepen.io/FelixRilling/pen/vLRNvb

const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop) 

const tagManagerArgs = {
    gtmId: 'UA-72377409-8'
}
TagManager.initialize(tagManagerArgs)

const advancedMatching = { em: '' }; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
const options = {
  autoConfig: true, // set pixel's autoConfig
  debug: false, // enable logs
};
ReactPixel.init('531537167679147', advancedMatching, options);
ReactPixel.pageView(); // For tracking page view

const Home = props => {

    var signupUrl = document.getElementById("signup_url").value;
    const homeDiv = useRef(null)
    const overviewDiv = useRef(null)
    const featureDiv = useRef(null)

    const executeScrollHome = () => scrollToRef(homeDiv)
    const executeScrollOverview = () => scrollToRef(overviewDiv)
    const executeScrollFeature = () => scrollToRef(featureDiv)
    const [cookies, setCookie] = useCookies(['_cookie_accept'])

    var shouldHide = false

    if(cookies['_cookie_accept']){
        shouldHide = true
    }

    function cookieAccept() {
        var exdays = 5000;
        var d = new Date();
        d.setTime(d.getTime() + (exdays*24*60*60*1000));
        setCookie('_cookie_accept', '1', { path: '/', d });
        var element = document.getElementById("_cookie_accept_box");
        element.parentNode.removeChild(element);
    }

    return (
        <div id="home-page">
            <BurgerMenu />
            <header className="header" ref={homeDiv}>
                <img className="header__logo" src="/assets/logo.png" alt="logo" />
                <ul className="header__nav">
                    <li className="header__nav__link"><a className="pointer" type="button" onClick={executeScrollOverview}>overview</a></li>
                    <li className="header__nav__link"><a href="https://support.elify.com/support/home" target="_blank">faqs</a></li>
                    <li className="header__nav__link"><a className="pointer" type="button" onClick={executeScrollFeature}>features</a></li>
                </ul>
                <div className="header__btns">
                    <a className="btn btn--header btn--lightblue" href={signupUrl}>get shuffle</a>
                    <a className="btn btn--header btn--blue" href="https://app.elify.com/#!/signin">sign in</a>
                </div>
            </header>

            <section className="heromobile">
                <div className="heromobile__content">
                    <div className="heromobile__content__title">YOUR BUSINESS HAS EVOLVED</div>
                    <div className="heromobile__content__text">NOW YOUR BUSINESS CARD HAS TOO</div>
                </div>
            </section>

            <section className="hero">
                <div className="container hero__phonecontainer">
                    <div className="hero__content">
                        <div className="hero__content__title">YOUR BUSINESS HAS EVOLVED</div>
                        <div className="hero__content__text">NOW YOUR BUSINESS CARD HAS TOO</div>
                        <a className="btn btn--hero btn--lightblue" href={signupUrl}>try shuffle</a>
                        <div className="disclaimer">No obligations, no contracts, cancel at any time.</div>
                    </div>
                    <div className="hero__phoneframe">
                        <div id="wrapper">
                            <div className="phone view_3" id="phone_1">
                                <iframe src="/card.html" id="frame_1"></iframe>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="hero__scroll_here">
                    <img src="./assets/scroll_here.png" />
                </div>
            </section>

            <section className="heromobile">
                <div className="heromobile__content">
                    <a className="btn btn--hero btn--lightblue" href={signupUrl}>try shuffle</a>
                    <div className="disclaimer">No obligations, no contracts, cancel at any time.</div>
                </div>
            </section>

            <section className="build-marketing" ref={overviewDiv}>
                <div className="section-title-blue" >TRADITIONAL BUSINESS CARDS ARE FORGETTABLE</div>
            </section>

            <section className="build-marketing">
                <div className="card">
                    <img className="card__img" src="/assets/entr.jpg" alt="" />
                    <div className="right-fix">
                        <div className="card__content">
                            <div className="card__title">YOU NEED TO LEAVE A LASTING IMPRESSION IF YOU WANT A LEAD TO TURN INTO A SALE.</div>
                            <div className="card__text">
                                But physical cards aren’t memorable, and they’re easily misplaced.
                                <br /><br/>
                                Shuffle’s digital cards are SSL Secure and web-based, making them ideal for advertising, marketing and promotion. This means anywhere you can share a web URL, you can share a Shuffle card - your audience won’t need a special app to view, save, forward, or engage with your card.
                            </div>
                            <br />
                            <div className="card__link_container">
                                <a className="btn btn--hero btn--lightblue card__link" href={signupUrl}>TRY SHUFFLE</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="get-started" ref={featureDiv}>
                <div className="section-title gradient-back-left">CREATE CUSTOM DIGITAL CARDS THAT<br/> TRULY REFLECT WHO YOU ARE</div>
                    <section className="icons">
                        <div className="container">
                            <div className="hero__icons">
                                <div className="hero__icon hero__icon--bigger">
                                    <img src="./assets/img1.jpg" alt="icon" className="hero__icon__image" />
                                    <div className="hero__icon__text">
                                        <div className="hero__icon__text__head">
                                            Create Customized Designs
                                        </div>
                                        <div className="hero__icon__text__body">
                                        Use one of our templates to build a custom card, or work with our team to create a custom template unique to your personal brand.
                                        </div>
                                    </div>
                                </div>
                                <div className="hero__icon hero__icon--bigger">
                                    <img src="./assets/img8.jpg" alt="icon" className="hero__icon__image" />
                                    <div className="hero__icon__text">
                                        <div className="hero__icon__text__head">
                                            Link to Social Media
                                        </div>
                                        <div className="hero__icon__text__body">
                                        Put your most important contact information just a tap away.
                                        </div>
                                    </div>
                                </div>
                                <div className="hero__icon hero__icon--bigger">
                                    <img src="./assets/img5.jpg" alt="icon" className="hero__icon__image" />
                                    <div className="hero__icon__text">
                                        <div className="hero__icon__text__head">
                                            Optimize Your Materials
                                        </div>
                                        <div className="hero__icon__text__body">
                                        Upgrade your subscription and enjoy a full set of 10 cards to use however and whenever you’d like. There’s never any limit to the number of edits you can make or the number of times you can share your cards.
                                        </div>
                                    </div>
                                </div>
                                <div className="hero__icon hero__icon--bigger">
                                    <img src="./assets/img7.jpg" alt="icon" className="hero__icon__image" />
                                    <div className="hero__icon__text">
                                        <div className="hero__icon__text__head">
                                            Track Campaign Success
                                        </div>
                                        <div className="hero__icon__text__body">
                                        Support your advertising and marketing efforts with trackable campaigns so you always know how your message is performing.
                                        </div>
                                    </div>
                                </div>
                                <div className="hero__icon hero__icon--bigger">
                                    <img src="./assets/img3.jpg" alt="icon" className="hero__icon__image" />
                                    <div className="hero__icon__text">
                                        <div className="hero__icon__text__head">
                                            Go Contactless with QR Codes
                                        </div>
                                        <div className="hero__icon__text__body">
                                        QR Codes make “face to face” sharing efficiant and print application a breeze.
                                        </div>
                                    </div>
                                </div>
                                <div className="hero__icon hero__icon--bigger">
                                    <img src="./assets/img2.jpg" alt="icon" className="hero__icon__image" />
                                    <div className="hero__icon__text">
                                        <div className="hero__icon__text__head">
                                            Highlight Products
                                        </div>
                                        <div className="hero__icon__text__body">
                                        Highlight your products, showcase your stunning photos and embed videos all on one easy to view card.
                                        </div>
                                    </div>
                                </div>
                                <div className="hero__icon hero__icon--bigger">
                                    <img src="./assets/img4.jpg" alt="icon" className="hero__icon__image" />
                                    <div className="hero__icon__text">
                                        <div className="hero__icon__text__head">
                                            Set Follow Up Reminders
                                        </div>
                                        <div className="hero__icon__text__body">
                                        Set follow-up reminders and leave notes for your next encounter so a lead never slips through your fingers again.
                                        </div>
                                    </div>
                                </div>
                                <div className="hero__icon hero__icon--bigger">
                                    <img src="./assets/img6.jpg" alt="icon" className="hero__icon__image" />
                                    <div className="hero__icon__text">
                                        <div className="hero__icon__text__head">
                                            View Engagement
                                        </div>
                                        <div className="hero__icon__text__body">
                                        Track viewer activity and engagement withyour card. See exactly who viewed your card,and get notified when they viewed it. Trackhow much time they spend on it, and where they clicked.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
            </section>

            <section className="intro">
                <div className="section-content gradient-back-right">
                    <div className="container">
                        <div className="intro__text">
                            <p>“As a realtor, I love Shuffle! I always have my digital business cards available and ready to share with potential customers, and it is so powerful to be able to share so much information - unlimited videos, unlimited pictures, unlimited linkable buttons, all my social media accounts and more.</p>
                            <br />
                            <p>Everyone who is serious about this business should have<br />
                            this tool in their toolbag.”</p>
                            <br />
                            <p>- John B.</p>
                            <p>Customer Engagement <br />Lead at ABT Group</p>
                        </div>
                        <div className="intro__img">
                            <img src="./assets/intro_portrait.jpg" alt="icon" />
                        </div>
                    </div>
                </div>
            </section>

            <section className="impressions2">
            <div className="impressions2__title">DO MORE WITH SHUFFLE</div>
                <div className="container">
                    <Swiper
                        navigation
                        loop
                        pagination
                        breakpoints={{
                            1024: {
                                slidesPerView: 3,
                                spaceBetween: 16,
                            },
                            768: {
                                slidesPerView: 3,
                                spaceBetween: 16,
                            },
                            480: {
                                slidesPerView: 1,
                                spaceBetween: 16, 
                            }
                        }}
                        >
                        <SwiperSlide>
                            <>
                                <div className="impressions2__img">
                                    <img src="./assets/feed1.jpg" />
                                </div>
                                <div className="impressions2__name">
                                Lead Capture
                                </div>
                                <div className="impressions2__text">
                                Acquire lead information that can potentially that can potentially convert a lead to a customer.
                                </div>
                            </>
                        </SwiperSlide>
                        <SwiperSlide>
                            <>
                                <div className="impressions2__img">
                                    <img src="./assets/feed2.jpg" />
                                </div>
                                <div className="impressions2__name">
                                RELATIONSHIP MANAGEMENT
                                </div>
                                <div className="impressions2__text">
                                Never let a lead slip through the cracks. Create contact notes and follow up reminders.
                                </div>
                            </>
                        </SwiperSlide>
                        <SwiperSlide>
                            <>
                                <div className="impressions2__img">
                                    <img src="./assets/feed3.jpg" />
                                </div>
                                <div className="impressions2__name">
                                SALES FUNNEL
                                </div>
                                <div className="impressions2__text">
                                Create a dynamic step-by-step process for potential customers to purchase your products/services.
                                </div>
                            </>
                        </SwiperSlide>
                    </Swiper>
                        
                </div>
            </section>

            <section className="get-started">
                    <section className="icons">
                        <div className="container">
                            <a className="btn btn--lightblue" href={signupUrl}>LAST CHANCE TO TRY SHUFFLE</a>
                            <div className="disclaimer">No obligations, no contracts, cancel at any time.</div>
                        </div>
                    </section>
            </section>

            <section className="impressions">
                <div className="impressions__title">WHAT ENTREPRENEURS ARE SAYING ABOUT SHUFFLE</div>
                <div className="container gradient-back-left">
                    <Swiper
                        navigation
                        loop
                        breakpoints={{
                            1024: {
                                slidesPerView: 1,
                                spaceBetween: 16,
                            },
                            768: {
                                slidesPerView: 1,
                                spaceBetween: 16,
                            },
                            480: {
                                slidesPerView: 1,
                                spaceBetween: 16, 
                            }
                        }}
                        >
                        <SwiperSlide>
                            <>
                                <div className="impressions__img">
                                    <img src="./assets/testimonial1.jpg" />
                                </div>
                                <div className="impressions__text">
                                    “SHUFFLE! Shuffle has
                                    transformed my life and
                                    my time! I can build a
                                    card to onboard a new
                                    team member (send it out)
                                    and keep up with
                                    everything my day gives me!
                                    It’s a life saver!”
                                </div>
                                <div className="impressions__name">
                                    – Sara K.
                                </div>
                                <div className="impressions__img_mobile">
                                    <img src="./assets/testimonial1.jpg" />
                                </div>
                            </>
                        </SwiperSlide>
                        <SwiperSlide>
                            <>
                                <div className="impressions__img">
                                    <img src="./assets/testimonial2.jpg" />
                                </div>
                                <div className="impressions__text">
                                    “SHUFFLE! Shuffle has
                                    transformed my life and
                                    my time! I can build a
                                    card to onboard a new
                                    team member (send it out)
                                    and keep up with
                                    everything my day gives me!
                                    It’s a life saver!”
                                </div>
                                <div className="impressions__name">
                                    – Jen L.
                                </div>
                                <div className="impressions__img_mobile">
                                    <img src="./assets/testimonial2.jpg" />
                                </div>
                            </>
                        </SwiperSlide>
                        <SwiperSlide>
                            <>
                                <div className="impressions__img">
                                    <img src="./assets/testimonial3.jpg" />
                                </div>
                                <div className="impressions__text">
                                    “Without Shuffle I’d be lost.
                                    Being able to organize my
                                    customers into groups
                                    allows me to communicate
                                    with my customers when
                                    it really matters to them.
                                    They’re always impressed
                                    by how much I remember
                                    about them, even if we’ve
                                    only interacted once
                                    or twice.”
                                </div>
                                <div className="impressions__name">
                                    – John R.
                                </div>
                                <div className="impressions__img_mobile">
                                    <img src="./assets/testimonial3.jpg" />
                                </div>
                            </>
                        </SwiperSlide>
                        <SwiperSlide>
                            <>
                                <div className="impressions__img">
                                    <img src="./assets/testimonial4.jpg" />
                                </div>
                                <div className="impressions__text">
                                    “I love how Shuffle makes
                                    it so easy to share my
                                    business to potential
                                    customers, clients and
                                    partners. The ability
                                    to set up follow up reminders,
                                    take detail notes, run
                                    lead capture campaigns
                                    and track data allows
                                    me to remain in the drivers
                                    seat at all times.”
                                </div>
                                <div className="impressions__name">
                                    – Brian B.
                                </div>
                                <div className="impressions__img_mobile">
                                    <img src="./assets/testimonial4.jpg" />
                                </div>
                            </>
                        </SwiperSlide>
                        <SwiperSlide>
                            <>
                                <div className="impressions__img">
                                    <img src="./assets/testimonial5.jpg" />
                                </div>
                                <div className="impressions__text">
                                “I think the app is incredibly intelligent and extremely useful. I have used it effectively for over 2 years.”
                                </div>
                                <div className="impressions__name">
                                - Yvonne G.
                                </div>
                                <div className="impressions__img_mobile">
                                    <img src="./assets/testimonial5.jpg" />
                                </div>
                            </>
                        </SwiperSlide>
                        <SwiperSlide>
                            <>
                                <div className="impressions__img">
                                    <img src="./assets/testimonial6.jpg" />
                                </div>
                                <div className="impressions__text">
                                “Love the ability to give business cards to everyone electronically. This is a great app!”
                                </div>
                                <div className="impressions__name">
                                - Anne B.
                                </div>
                                <div className="impressions__img_mobile">
                                    <img src="./assets/testimonial6.jpg" />
                                </div>
                            </>
                        </SwiperSlide>
                        <SwiperSlide>
                            <>
                                <div className="impressions__img">
                                    <img src="./assets/testimonial7.jpg" />
                                </div>
                                <div className="impressions__text">
                                “Best app on the market for virtual business cards. Engage your prospects with links to your websites, social media pages, and embedded videos right on your card. Built in CRM with follow up capability. Amazing!”
                                </div>
                                <div className="impressions__name">
                                - Gabriel T.
                                </div>
                                <div className="impressions__img_mobile">
                                    <img src="./assets/testimonial7.jpg" />
                                </div>
                            </>
                        </SwiperSlide>
                        <SwiperSlide>
                            <>
                                <div className="impressions__img">
                                    <img src="./assets/testimonial8.jpg" />
                                </div>
                                <div className="impressions__text">
                                “This is a must if you are in business”
                                </div>
                                <div className="impressions__name">
                                - James S.
                                </div>
                                <div className="impressions__img_mobile">
                                    <img src="./assets/testimonial8.jpg" />
                                </div>
                            </>
                        </SwiperSlide>
                        <SwiperSlide>
                            <>
                                <div className="impressions__img">
                                    <img src="./assets/testimonial9.jpg" />
                                </div>
                                <div className="impressions__text">
                                “I've tried every app on the market for engaging with new contacts and following up over time - Shuffle does it fast, professional and easy! The fortune is in the followup and Shuffle makes it Simple!”
                                </div>
                                <div className="impressions__name">
                                - Allie C.
                                </div>
                                <div className="impressions__img_mobile">
                                    <img src="./assets/testimonial9.jpg" />
                                </div>
                            </>
                        </SwiperSlide>
                        <SwiperSlide>
                            <>
                                <div className="impressions__img">
                                    <img src="./assets/testimonial10.jpg" />
                                </div>
                                <div className="impressions__text">
                                “Whole new experience of making connections with prospects and monitoring their engagement with your content.”
                                </div>
                                <div className="impressions__name">
                                - Dallas H.
                                </div>
                                <div className="impressions__img_mobile">
                                    <img src="./assets/testimonial10.jpg" />
                                </div>
                            </>
                        </SwiperSlide>
                    </Swiper>
                </div>
            </section>

            <footer className="footer gradient-back-left">
                <div className="container">
                    <div className="footer-row">
                        <div className="logo-wrap">
                            <img className="logo" alt="" src="./assets/white-logo.png"/>
                            <div className="elify-text">Smart and fast solutions<br/> to help you succeed.</div>
                        </div>
                        <div className="footer-columns">
                            <div className="footer-column">
                                <div className="column-title">PRODUCTS</div>
                                <div className="column-item"><a className="pointer" type="button" onClick={executeScrollOverview}>Overview</a></div>
                                <div className="column-item"><a href="https://support.elify.com/support/home" target="_blank">FAQs</a></div>
                                <div className="column-item"><a className="pointer" type="button" onClick={executeScrollFeature}>Features</a></div>
                                <div className="column-item"><a href={signupUrl}>Pricing</a></div>
                            </div>
                            <div className="footer-column">
                                <div className="column-title">COMPANY</div>
                                <div className="column-item"><a href="https://elifyshuffle.com/affiliate">Affiliate Program</a></div>
                                <Popup trigger={<div className="column-item pointer"> Contact </div>} 
                                    modal nested>
                                    {close => (
                                    <div className="modal">
                                        <button className="close" onClick={close}>
                                        &times;
                                        </button>
                                        <div className="header"> We would love to hear from you! </div>
                                        <div className="content">
                                        {' '}
                                        Thanks for reaching out, we would love to hear from you. Please email us at:
                                        <br />
                                        <a className="text special cyan" href="mailto:support@elify.com">support@elify.com</a>
                                        <br />
                                        <span className="text">We will get back to you as soon as possible.</span>
                                        <br />
                                        <span className="text">Thank you, <br />
                                        <span className="cyan">Team Elify</span></span>
                                        </div>
                                    </div>
                                    )}
                                </Popup>
                                <Popup trigger={<div className="column-item pointer"> Legal </div>} 
                                    modal nested>
                                    {close => (
                                    <div className="modal">
                                        <button className="close-legal" onClick={close}>
                                        &times;
                                        </button>
                                        <div className="content-legal">
                                            <iframe id="modal-iframe" src="https://app.elify.com/legal" frameBorder="0" />
                                        </div>
                                    </div>
                                    )}
                                </Popup>
                            </div>
                            <div className="footer-column">
                                <div className="column-title">SUPPORT</div>
                                <div className="column-item"><a href="https://www.facebook.com/groups/ElifyInsiders" target="_blank">Insiders</a></div>
                                <div className="column-item"><a href="https://support.elify.com/support/home" target="_blank">Help Desk</a></div>
                            </div>
                        </div>
                    </div>
                    <div className="footer-icons">
                        <a className="footer-icon" href="https://www.instagram.com/goelify" target="_blank"><i className="fab fa-instagram"></i></a>
                        <a className="footer-icon" href="https://www.facebook.com/GoElify" target="_blank"><i className="fab fa-facebook-f"></i></a>
                        <a className="footer-icon" href="https://www.pinterest.com/elifyshuffle" target="_blank"><i className="fab fa-pinterest"></i></a>
                        <a className="footer-icon" href="https://twitter.com/ElifySolutions" target="_blank"><i className="fab fa-twitter"></i></a>
                        <a className="footer-icon" href="https://www.youtube.com/channel/UCYC7qEtULwjSFfcdKTeaW3w" target="_blank"><i className="fab fa-youtube"></i></a>
                        <a className="footer-icon" href="https://www.linkedin.com/company/elify" target="_blank"><i className="fab fa-linkedin-in"></i></a>
                    </div>
                    <div className="reserved">
                        ©2023 SHUFFLE By Elify, All rights reserved    
                    </div>
                </div>
            </footer>
            <div className={shouldHide?'hidden':''}>
                <div id="_cookie_accept_box" className="cookieaccept">
                    <div>
                        <div className="cookieaccept__content">
                            We use Cookies to enhance your user experience. You can view a complete overview of our <a href="https://elify.com/legal#terms6" target="_blank">Cookie Policy</a>, <a href="https://elify.com/legal#terms2" target="_blank">Privacy Policy</a> and <a href="https://elify.com/legal" target="_blank">User Terms of Service</a>  for more information.
                        </div>
                        <div className="cookieaccept__gotit" id="_cookie_accept_buttons_pad">
                            <button id="_cookie_accept_button" onClick={cookieAccept}>Got It</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Home